import React from 'react';
import Markdown from 'markdown-to-jsx';

import {ContentLibrarySideInnerWrapper, ContentLibrarySideWrapper} from "../styled/pages/about/content-library/ContentLibraryComponents";

const HighlightedCard = ({children}) => {
    return (
        <ContentLibrarySideWrapper>
            <ContentLibrarySideInnerWrapper padding>
                <Markdown children={children} className="markdown"/>
            </ContentLibrarySideInnerWrapper>
        </ContentLibrarySideWrapper>
    );
};

export default HighlightedCard;